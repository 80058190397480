export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.deviceUri) {
    const { deviceUri } = to.params;
    try {
      const device = await getDeviceByUri(deviceUri as string);
      if (!device) {
        return navigateTo("/not-found");
      }
      const deviceState = useState("deviceByUri", () => device);
      deviceState.value = device;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return navigateTo("/not-found");
      }
    }
  }
});
